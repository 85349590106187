import React from "react";
import styled from "styled-components";
import moment from "moment";
import { TextField } from "../fields/TextField/TextField";
import { useStateValue } from "../../../context/StateProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";

function BlockFormView({ data }) {
  const [{ fields }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const handleChildFieldRemove = (id) => {
    if (window.confirm(`Are you sure you want to delete the entry? `)) {
      enqueueSnackbar(`Entry id ${data.id} removed`, {
        variant: "warning",
      });
      dispatch({
        type: "REMOVE_CHILD_FIELD",
        id: id,
      });
    }
  };

  return (
    <Container>
      <AbsoluteContainer>
        <IconButton onClick={() => handleChildFieldRemove(data?.id)}>
          <DeleteIcon style={{ color: "#e73636" }} />
        </IconButton>
      </AbsoluteContainer>
      <TextField label="Name" disabled value={data.childName} />
      <TextField
        label="Date of Birth"
        disabled
        value={moment(data?.childDob).format("MM/DD/YYYY")}
      />
      <TextField label="Gender" disabled value={data.childGender} />
    </Container>
  );
}

export default BlockFormView;

const AbsoluteContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #b6b6b642;
  opacity: 0;
  transition: opacity 300ms ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  border-radius: 10px;
  position: relative;
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  background-color: #c3d0e181;
  padding: 1.2rem;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem 0rem;
  @media (max-width: 768px) {
    display: flex;
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

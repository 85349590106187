import React, { Fragment, useEffect, useState } from "react";
import { Label } from "../shared/fields/TextField/CustomTextField";
import { ColumnContainer, ColumnWrapper, FlexContainer } from "./ProfileView";
import Select from "react-select";
import { CustomButton } from "../shared/fields/Button/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  createConsent,
  referConsent,
  retrieveMerchants,
  retrieveConsents,
} from "../../api/Api";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import * as encryption from "../../utils/encryption";
import ActionConfirm from "../../pages/ActionConfirm";
import { getUserSession } from "../../services/UserManagement";
import { useSnackbar } from "notistack";
import SendIcon from "@mui/icons-material/Send";
import FieldSelectionModal from "../shared/Modal/FieldSelectionModal";

const options = [
  { value: 1, label: "06 Months" },
  { value: 2, label: "12 Months" },
  { value: 3, label: "24 Months" },
];

const OptionsSwitch = ({ data, onPress, isSelected, id, type, publicKey }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [selectOptions, setSelectOptions] = useState(options[0]);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [createIsSent, setCreateIsSent] = useState(false);
  const [referIsSent, setReferIsSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(10);
  const [merchant, setMerchant] = useState(null);
  const [referalAccounts, setReferalAccounts] = useState([]);
  const [referLoading, setReferLoading] = useState(false);
  const location = useLocation();
  const [showAction, setShowAction] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [apiSwitch, setApiSwitch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fieldSelections, setFieldSelections] = useState([]);
  const [openSelectionModal, seOpenSelectionModal] = useState(false);

  // function to get merchants whom the user is connected with to the select
  const getUserMerchants = async () => {
    try {
      const data = {
        token: localStorage.niftoken,
        query: { type: "merchant-interaction", status: "accepted" },
      };
      const response = await retrieveConsents(data);
      if (response.data.code === 200) {
        let arr = [];
        response.data.data.map((data) => {
          const obj = {
            value:
              currentUser?.publicKey === data.requestedBy
                ? data.requestedTo
                : data.requestedBy,
            label:
              currentUser?.publicKey === data.requestedBy
                ? data.requestedToAlias
                : data.requestedByAlias,
          };
          arr = [...arr, obj];
        });
        setReferalAccounts(arr);
      }
    } catch (error) {
      // enqueueSnackbar(`NO CONSENTS GIVEN OR NO USERS FOUND!`, {
      //   variant: "error",
      // });
      setReferalAccounts([]);
    }
  };

  const getAllMerchants = async () => {
    try {
      const response = await retrieveMerchants();
      if (response.data.code === 200) {
        let arr = [];
        response.data.data.map((data) => {
          if (currentUser.publicKey !== data.publicKey) {
            arr = [...arr, { value: data.publicKey, label: data.alias }];
          }
        });
        setReferalAccounts(arr);
      }
    } catch (error) {
      enqueueSnackbar(
        `Could not fetch merchants at the moment. Please try again later`,
        {
          variant: "error",
        }
      );
    }
  };

  const filterSelectedFields = () => {
    let filteredFields = {};
    for (let [key, value] of Object.entries(currentUser.userOwnedData)) {
      if (currentUser.type === "user") {
        if (fieldSelections.find((e) => e.name === key)) {
          filteredFields = { ...filteredFields, [key]: value };
        }
      } else {
        filteredFields = { ...filteredFields, [key]: value };
      }
    }
    return filteredFields;
  };

  // TODO: Can be generalized into a util function
  const prepareEncryptedData = (toPublicKey) => {
    const userEncryptedData = filterSelectedFields();
    delete userEncryptedData.updatedAt;
    delete userEncryptedData.createdAt;
    const userSecretKey = currentUser.secretKey;
    let asymmetricEncryptedData = {};
    for (let [key, value] of Object.entries(userEncryptedData)) {
      if (
        key !== "brcImage" &&
        key !== "profileImage" &&
        key !== "logo" &&
        key !== "birthCertificate" &&
        key !== "drivingLicense" &&
        key !== "marriageCertificate" &&
        key !== "signature" &&
        key !== "managerSignature" &&
        key !== "passportImage" &&
        key !== "addressImage"
      ) {
        let field;
        // if merchant type
        if (currentUser.type === "merchant") {
          field = value.data;
        } else {
          field = encryption.symmetricDecryption(value.data, userSecretKey);
        }
        // Asymmetric encryption
        asymmetricEncryptedData[key] = encryption.asymmetricEncryption(
          field,
          toPublicKey,
          userSecretKey
        );
      } else {
        let field = [];
        // if merchant type
        if (currentUser.type === "merchant") {
          field = value;
        } else {
          const tempArr = [];
          userEncryptedData[key]?.map((item, key) => {
            const enc = encryption.symmetricDecryption(
              item.data,
              userSecretKey
            );
            tempArr.push(enc);
          });
          field = tempArr;
        }
        // Asymmetric encryption
        const tempFieldArr = [];
        field?.map((item, key) => {
          const toBeEncrypted =
            currentUser?.type === "merchant" ? item.data : item;
          const enc = encryption.asymmetricEncryption(
            toBeEncrypted,
            toPublicKey,
            userSecretKey
          );
          tempFieldArr.push(enc);
        });
        asymmetricEncryptedData[key] = tempFieldArr;
      }
    }
    return asymmetricEncryptedData;
  };

  // checking the interactions with the current user
  const checkInteractions = () => {
    currentUser.interactions.forEach((element) => {
      if (element.publicKey === data.publicKey && element.status === 0) {
        setStatus(0);
      }
      if (element.publicKey === data.publicKey && element.status === 1) {
        setStatus(1);
      }
      if (element.publicKey === data.publicKey && element.status === 2) {
        setStatus(2);
      }
    });
  };

  const createConsentsAPI = async (consentData) => {
    setRevokeLoading(true);
    const encryptedData =
      consentData.type === "remove"
        ? {}
        : prepareEncryptedData(consentData?.toPublicKey);
    try {
      setLoading(true);
      const response = await createConsent({
        token: localStorage.niftoken,
        body: {
          requestedTo: data?.publicKey,
          requestedToAlias: data?.alias,
          requestedByAlias: currentUser?.alias,
          action: consentData.action,
          validity: selectOptions.value,
          fromData: encryptedData,
          requestedFields:
            currentUser.type === "merchant" ? fieldSelections : [],
        },
      });

      if (response.data.code === 201) {
        setCreateIsSent(true);
        enqueueSnackbar(`Request sent successfully`, {
          variant: "success",
        });
      } else if (response.data.code === 200) {
        enqueueSnackbar(`Request pending`, {
          variant: "warning",
        });
      }
    } catch (error) {
      enqueueSnackbar(`Creating consent failed. Please try again later`, {
        variant: "error",
      });
    } finally {
      setRevokeLoading(false);
      setLoading(false);
      setShowAction(false);
    }
  };

  const referMerchantAPI = async () => {
    setReferLoading(true);
    try {
      setLoading(true);
      const response = await referConsent({
        token: localStorage.niftoken,
        body: {
          requestedTo: merchant?.value,
          requestedToAlias: merchant?.label,
          requestedByAlias: data?.userData?.requestedByAlias,
          requestedBy: data?.userData?.requestedBy,
          refferedByAlias: currentUser?.alias,
        },
      });
      if (response.data.code === 201) {
        setReferIsSent(true);
        enqueueSnackbar(`Request sent successfully`, {
          variant: "success",
        });
      } else if (response.data.code === 200) {
        enqueueSnackbar(`Request pending`, {
          variant: "warning",
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `Could not process merchant referal. Please try again later`,
        {
          variant: "error",
        }
      );
    } finally {
      setReferLoading(false);
      setLoading(false);
      setShowAction(false);
    }
  };

  const handleApproveConsents = () => {
    if (currentUser.userOwnedData) {
      setLoading(true);
      createConsentsAPI({
        action:
          currentUser.type === "merchant"
            ? "m2u"
            : data.type === "user"
            ? "u2u"
            : "u2m",
        toPublicKey: data?.publicKey,
        type: "approve",
      });
      setLoading(false);
    } else {
      enqueueSnackbar(`Please fill your KYC details to proceed`, {
        variant: "warning",
      });
    }
    setShowAction(false);
  };

  const handleRemoveConsents = () => {
    if (currentUser.userOwnedData) {
      // createConsentsAPI({
      //   action:
      //     currentUser.type === "merchant" ? "m2u" : "u2u",
      //   fromData: {},
      //   type: "remove",
      // });
      setLoading(true);
      createConsentsAPI({
        action:
          currentUser.type === "merchant"
            ? "m2u"
            : data.type === "user"
            ? "u2u"
            : "u2m",
        fromData: {},
        type: "remove",
      });
      setLoading(false);
    } else {
      enqueueSnackbar(`Please fill your KYC details to proceed`, {
        variant: "warning",
      });
    }
    setShowAction(false);
  };

  const rejectOnPress = () => {
    if (currentUser.userOwnedData) {
      setLoading(true);
      onPress({
        status: "reject",
        id: id,
        publicKey: publicKey,
      });
      setLoading(false);
    } else {
      enqueueSnackbar(`Please fill your KYC details to proceed`, {
        variant: "warning",
      });
    }
    setShowAction(false);
  };

  const approveOnPress = () => {
    if (currentUser.userOwnedData) {
      setLoading(true);
      onPress({
        status: "approve",
        id: id,
        publicKey: publicKey,
      });
      setLoading(false);
    } else {
      enqueueSnackbar(`Please fill your KYC details to proceed`, {
        variant: "warning",
      });
    }
    setShowAction(false);
  };

  const handleClick = async (e) => {
    setApiSwitch(e);
  };

  useEffect(() => {
    if (apiSwitch != null) {
      if (apiSwitch === 1 || apiSwitch === 2) {
        seOpenSelectionModal(true);
      } else if (apiSwitch === 5) {
        approveOnPress();
      } else {
        setShowAction(true);
      }
    }
  }, [apiSwitch]);

  const apiSwitchFunction = () => {
    if (apiSwitch === 1) {
      return handleRemoveConsents();
    } else if (apiSwitch === 2) {
      return handleApproveConsents();
    } else if (apiSwitch === 3) {
      return referMerchantAPI();
    } else if (apiSwitch === 4) {
      return rejectOnPress();
    } else if (apiSwitch === 5) {
      return approveOnPress();
    }
    return;
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser.type === "user") {
        getUserMerchants();
      } else if (currentUser.type === "merchant") {
        getAllMerchants();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setCreateIsSent(false);
    setStatus(10); //clear the old state
    checkInteractions();
  }, [data]);

  return (
    <Fragment>
      <FieldSelectionModal
        openSelectionModal={openSelectionModal}
        setFieldSelections={setFieldSelections}
        setShowAction={setShowAction}
      />
      {(() => {
        switch (data?.status) {
          case 1:
            return (
              <ColumnContainer>
                {showAction && (
                  <ActionConfirm
                    successMessage={{
                      message: "Adding KYC details.",
                      width: "9.7rem",
                    }}
                    warningMessage={"Are you sure you want to continue?"}
                    loading={loading}
                    setShowAction={setShowAction}
                    authTypeInput={authUser.authType}
                    publicKey={authUser.publicKey}
                    encryptedSecret={authUser.encryptedSecret}
                    onSecretKey={apiSwitchFunction}
                  />
                )}
                <ColumnWrapper>
                  {location.pathname === "/dashboard/corporates" ? (
                    <Fragment>
                      <Label>Select Duration</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            outline: "none",
                            text: "orangered",
                            primary25: "#2f3a6039",
                            primary: " #2f3a60a7",
                            neutral0: "#EAEAF3",
                            boxShadow: "none",
                          },
                        })}
                        defaultValue={options[0]}
                        options={options}
                        onChange={setSelectOptions}
                      />
                    </Fragment>
                  ) : status === 0 || status === 1 || status === 2 ? (
                    <Fragment></Fragment>
                  ) : (
                    <Fragment>
                      <Label>Select Duration</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            outline: "none",
                            text: "orangered",
                            primary25: "#2f3a6039",
                            primary: " #2f3a60a7",
                            neutral0: "#EAEAF3",
                            boxShadow: "none",
                          },
                        })}
                        defaultValue={options[0]}
                        options={options}
                        onChange={setSelectOptions}
                      />
                    </Fragment>
                  )}
                </ColumnWrapper>
                <ColumnWrapper>
                  {status === 1 ? (
                    <CustomButton
                      className="dangerTab"
                      tab
                      text={!revokeLoading && "Remove"}
                      warning
                      onclick={() => {
                        handleClick(1);
                      }}
                      disabled={revokeLoading}
                      icon={
                        revokeLoading ? (
                          <CircularProgress size={25} />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                    />
                  ) : (
                    <CustomButton
                      tab
                      text={
                        !revokeLoading && (status === 0 || status === 2)
                          ? "Pending"
                          : createIsSent
                          ? "Request Sent"
                          : "Send Request"
                      }
                      onclick={() => {
                        handleClick(2);
                      }}
                      disabled={
                        revokeLoading ||
                        createIsSent ||
                        status === 0 ||
                        status === 2
                      }
                      icon={revokeLoading && <CircularProgress size={25} />}
                    />
                  )}
                </ColumnWrapper>
              </ColumnContainer>
            );
          case 0:
            if (isSelected === "Approved") {
              return (
                <ColumnContainer>
                  {showAction && (
                    <ActionConfirm
                      successMessage={{
                        message: "Adding KYC details.",
                        width: "9.7rem",
                      }}
                      warningMessage={"Are you sure you want to continue?"}
                      loading={loading}
                      setShowAction={setShowAction}
                      authTypeInput={authUser.authType}
                      publicKey={authUser.publicKey}
                      encryptedSecret={authUser.encryptedSecret}
                      onSecretKey={apiSwitchFunction}
                    />
                  )}
                  <CustomButton
                    tab
                    className="successTab"
                    text="Approved"
                    disabled={approveLoading}
                    icon={
                      approveLoading ? (
                        <CircularProgress size={25} />
                      ) : (
                        <AddCircleIcon />
                      )
                    }
                  />
                </ColumnContainer>
              );
            } else if (isSelected === "Rejected") {
              return (
                <ColumnContainer>
                  {showAction && (
                    <ActionConfirm
                      successMessage={{
                        message: "Adding KYC details.",
                        width: "9.7rem",
                      }}
                      warningMessage={"Are you sure you want to continue?"}
                      loading={loading}
                      setShowAction={setShowAction}
                      authTypeInput={authUser.authType}
                      publicKey={authUser.publicKey}
                      encryptedSecret={authUser.encryptedSecret}
                      onSecretKey={apiSwitchFunction}
                    />
                  )}
                  <CustomButton
                    className="dangerTab"
                    tab
                    text="Rejected"
                    warning
                    disabled={deleteLoading}
                    icon={
                      deleteLoading ? (
                        <CircularProgress size={25} />
                      ) : (
                        <DeleteIcon />
                      )
                    }
                  />
                </ColumnContainer>
              );
            }
            return (
              <ColumnContainer>
                {/* {location.pathname === "/dashboard/corporates" && (
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        outline: "none",
                        text: "orangered",
                        primary25: "#2f3a6039",
                        primary: " #2f3a60a7",
                        neutral0: "#EAEAF3",
                        boxShadow: "none",
                      },
                    })}
                    defaultValue={{
                      value: "none",
                      label: "Select Visibility",
                    }}
                    options={options}
                  />
                )} */}
                {showAction && (
                  <ActionConfirm
                    successMessage={{
                      message: "Adding KYC details.",
                      width: "9.7rem",
                    }}
                    warningMessage={"Are you sure you want to continue?"}
                    loading={loading}
                    setShowAction={setShowAction}
                    authTypeInput={authUser.authType}
                    publicKey={authUser.publicKey}
                    encryptedSecret={authUser.encryptedSecret}
                    onSecretKey={apiSwitchFunction}
                  />
                )}
                {type && type === 1 ? (
                  <Fragment>
                    {data.userData &&
                    location.pathname === "/dashboard/users" ? (
                      <Fragment>
                        <Label>Refer a Merchant</Label>
                        <Select
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              outline: "none",
                              text: "orangered",
                              primary25: "#2f3a6039",
                              primary: " #2f3a60a7",
                              neutral0: "#eaeaf3",
                              boxShadow: "none",
                            },
                          })}
                          // isMulti
                          // name="merchants"
                          // className="basic-multi-select"
                          // classNamePrefix="select"
                          options={referalAccounts}
                          value={merchant}
                          onChange={setMerchant}
                          placeholder="Select Merchant"
                        />
                        <CustomButton
                          className="successTab"
                          tab
                          text={
                            referIsSent ? "Referral Sent" : "Refer Merchant"
                          }
                          disabled={referLoading || referIsSent}
                          icon={
                            referLoading ? (
                              <CircularProgress size={25} />
                            ) : (
                              <SendIcon />
                            )
                          }
                          onclick={() => {
                            setReferIsSent(false);
                            // merchant?.forEach((element) => {
                            //   referMerchantAPI(element);
                            //   });
                            handleClick(3);
                          }}
                        />
                      </Fragment>
                    ) : null}
                    <CustomButton
                      className="dangerTab"
                      tab
                      text="Remove"
                      warning
                      disabled={deleteLoading}
                      icon={
                        deleteLoading ? (
                          <CircularProgress size={25} />
                        ) : (
                          <DeleteIcon />
                        )
                      }
                      onclick={() => {
                        handleClick(4);
                      }}
                    />
                  </Fragment>
                ) : (
                  type &&
                  type === 2 && (
                    <FlexContainer>
                      <CustomButton
                        tab
                        className="successTab"
                        text="Approve"
                        onclick={() => {
                          handleClick(5);
                        }}
                        disabled={approveLoading}
                        icon={
                          approveLoading ? (
                            <CircularProgress size={25} />
                          ) : (
                            <AddCircleIcon />
                          )
                        }
                      />
                      <CustomButton
                        className="dangerTab"
                        tab
                        text="Remove"
                        warning
                        disabled={deleteLoading}
                        icon={
                          deleteLoading ? (
                            <CircularProgress size={25} />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        onclick={() => {
                          handleClick(4);
                        }}
                      />
                    </FlexContainer>
                  )
                )}
              </ColumnContainer>
            );
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

export default OptionsSwitch;

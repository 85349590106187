import styled from "styled-components";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import CustomTextField from "../../components/shared/fields/TextField/CustomTextField";
import { DateComponent } from "../../components/shared/Date/DateComponent";
import Select from "react-select";
import DropzoneComponent from "../../components/shared/fields/Dropzone/DropzoneComponent";
import { useSnackbar } from "notistack";
import store from "../../redux/reducers";
import { Container } from "../../pages/Profile/VerifyDetails";

// country select options
const options = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];

var phoneNoValidation =
  /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;
var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CorporateDetails = ({
  startDate,
  setStartDate,
  companyName,
  setCompanyName,
  addressL1,
  setAddressL1,
  addressL2,
  setAddressL2,
  city,
  setCity,
  country,
  setCountry,
  brcNo,
  setBrcNo,
  employeeCount,
  setEmployeeCount,
  email,
  setEmail,
  emailError,
  setEmailError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  logoImageBase64,
  setLogoImageBase64,
  setFormStep,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // formData
  const getFormData = (target) => {
    var productObject = {};
    const formData = new FormData(target);
    for (let [key, value] of formData.entries()) {
      productObject[key] = value;
    }
    // --- Country --- //
    productObject["country"] = country?.label;
    // --- Profile image --- //
    productObject["logo"] = logoImageBase64;
    return productObject;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (logoImageBase64.length === 0) {
      enqueueSnackbar(`Please input a logo image`, {
        variant: "error",
      });
      return false;
    } else if (!phoneNumberError || !emailError) {
      setFormStep(2);
    }
    try {
      store.dispatch({
        type: "ADD_FORM_DATA",
        payload: getFormData(e.currentTarget),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <GridContainer id="main-form" onSubmit={handleSubmit}>
        <CustomTextField
          label="Company Name*"
          placeholder="Company Name"
          id="companyName"
          required
          name="companyName"
          type="text"
          value={companyName}
          onChange={setCompanyName}
          color="#FF613F"
          maxLength="20"
        />
        <CustomTextField
          label="BRC No*"
          placeholder="BRC No"
          id="brcNo"
          required
          name="brcNo"
          type="number"
          value={brcNo}
          onChange={setBrcNo}
          color="#FF613F"
        />
        <CustomTextField
          label="Address Line 1*"
          placeholder="Address Line 1"
          id="addressL1"
          name="addressL1"
          type="text"
          value={addressL1}
          required
          onChange={setAddressL1}
          color="#FF613F"
          maxLength="50"
        />
        <CustomTextField
          label="Address Line 2*"
          placeholder="Address Line 2"
          id="addressL2"
          name="addressL2"
          type="text"
          required
          value={addressL2}
          onChange={setAddressL2}
          color="#FF613F"
          maxLength="50"
        />
        <CustomTextField
          label="City*"
          placeholder="City"
          id="city"
          name="city"
          type="text"
          value={city}
          required
          onChange={setCity}
          color="#FF613F"
          maxLength="20"
        />
        <ColumnContainer>
          <Label>Country*</Label>
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#eaeaf3",
                boxShadow: "none",
              },
            })}
            defaultValue={options[0]}
            value={country}
            onChange={setCountry}
            options={options}
          />
        </ColumnContainer>
        <CustomTextField
          label="Employee Count*"
          placeholder="Employee Count"
          id="employeeCount"
          name="employeeCount"
          type="number"
          value={employeeCount}
          required
          onChange={setEmployeeCount}
          color="#FF613F"
        />
        <DateComponent
          label="Date of Establishment*"
          value={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <CustomTextField
          label="Email*"
          placeholder="Email"
          id="email"
          name="email"
          type="text"
          value={email}
          required
          maxLength="50"
          onChange={(e) => {
            if (!emailValidation.test(e)) {
              setEmailError(true);
            } else {
              setEmailError(false);
            }
            setEmail(e);
          }}
          helper={emailError && "Invalid Email"}
          color="#FF613F"
        />
        <CustomTextField
          label="Mobile Number*"
          placeholder="0/ 94/ +94/ 0094"
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          value={phoneNumber}
          required
          maxLength="13"
          onChange={(e) => {
            if (!phoneNoValidation.test(e)) {
              setPhoneNumberError(true);
            } else {
              setPhoneNumberError(false);
            }
            setPhoneNumber(e);
          }}
          color="#FF613F"
          helper={phoneNumberError && "Invalid mobile number"}
        />
        <ColumnContainer style={{ gridColumn: "1/3" }}>
          <Label>Upload Merchant Logo*</Label>
          <DroppableContainer>
            <InnerContainer>
              <DropzoneComponent
                files={logoImageBase64}
                id="dropzone"
                amount="1"
                multiple={false}
                maxSize="1000000"
                setFiles={setLogoImageBase64}
                type="image/*, image/jpeg, image/png"
                description="Select or Drop an Image"
              />
            </InnerContainer>
          </DroppableContainer>
        </ColumnContainer>
        <ButtonWrapper>
          <CustomButton form="main-form" type="submit" login text="Continue" />
        </ButtonWrapper>
      </GridContainer>
    </Container>
  );
};

export default CorporateDetails;

const GridContainer = styled.form`
  display: grid;
  margin: 2rem;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    margin: 1rem;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

const Label = styled.label`
  color: #2f3a60;
  font-weight: 700;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

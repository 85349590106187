import {
  Container,
  Line,
  ScrollContainer,
  ScrollWrapper,
  SearchResultContainer,
  ViewLayout,
  WrapperContainer,
} from "../../components/shared/Styles/SharedStyles";
import styled from "styled-components";
import Banner from "./Banner";

//images
import Searching from "../../assets/images/searching.svg";
import Like from "../../assets/images/like.svg";
import Timeline from "../../assets/images/timeline.svg";
import AnonymousImage from "../../assets/images/anonymous.png";

import { Heading } from "../../components/shared/Typography/Typo";
import RowCard from "../../components/shared/core/Card/RowCard";
import ProfileView from "../../components/views/ProfileView";
import { useEffect, useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { retrieveConsents } from "../../api/Api";
import { CircularProgress } from "@mui/material";

import * as API from "../../api/Api";
import { useSelector } from "react-redux";
import * as encryption from "../../utils/encryption";
import { Text } from "../../components/shared/Typography/Typo";
import { useSnackbar } from "notistack";
import Table from "../../components/shared/Table/Table";
import {
  LoadingRow,
  TableContainer,
  TableWrapper,
} from "../Dashboard/Dashboard";
import FieldSelectionModal from "../../components/shared/Modal/FieldSelectionModal";

const theadData = [
  "Created",
  "Requested By",
  "Requested To",
  "Valid Until",
  "Status",
  "Type",
];

const CorporatesContainer = ({ view, setView }) => {
  const currentUser = useSelector((state) => state.currentUser);
  let componentMounted = true; // (3) component is mounted
  const [{ clickIdMerchant, merchants }, dispatch] = useStateValue();
  // states
  const [activeItem, setActiveItem] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [loadingAccepted, setAcceptedLoading] = useState(false);
  const [loadingPending, setPendingLoading] = useState(false);
  const [acceptedConsents, setAcceptedConsents] = useState([]);
  const [pendingConsents, setPendingConsents] = useState([]);
  const [loadingAll, setAllLoading] = useState(false);
  const [allConsents, setAllConsents] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [fieldSelections, setFieldSelections] = useState([]);
  const [openSelectionModal, seOpenSelectionModal] = useState(false);
  const [consentData, setConsentData] = useState();

  const { enqueueSnackbar } = useSnackbar();

  // api calls
  const retrieveAcceptedConsentsAPI = async () => {
    if (componentMounted) {
      setAcceptedLoading(true);
      const acceptedConsentsAPI = await retrieveConsents({
        token: localStorage.niftoken,
        query: {
          status: "accepted",
          type: "merchant-interaction",
          direction: "",
        },
      });

      if (componentMounted) {
        if (acceptedConsentsAPI) {
          setAcceptedConsents(acceptedConsentsAPI.data.data);
        }
        setAcceptedLoading(false);
      }
    }
  };

  const retrievePendingConsentsAPI = async () => {
    if (componentMounted) {
      setPendingLoading(true);

      const pendingConsentsAPI = await retrieveConsents({
        token: localStorage.niftoken,
        query: {
          status: "pending",
          type: "merchant-interaction",
          direction: "received",
        },
      });

      if (componentMounted) {
        if (pendingConsentsAPI) {
          setPendingConsents(pendingConsentsAPI.data.data);
        }
        setPendingLoading(false);
      }
    }
  };

  const retrieveAllConsents = async () => {
    if (componentMounted) {
      setAllLoading(true);
      try {
        const response = await API.retrieveConsents({
          query: {
            status: "",
            type: "merchant-interaction",
            direction: "",
          },
          token: localStorage.niftoken,
        });
        if (componentMounted) {
          if (response) {
            setAllConsents(response?.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setAllLoading(false);
      }
    }
  };

  const filterSelectedFields = () => {
    let filteredFields = {};
    for (let [key, value] of Object.entries(currentUser.userOwnedData)) {
      if (fieldSelections.find((e) => e.name === key)) {
        filteredFields = { ...filteredFields, [key]: value };
      }
    }
    return filteredFields;
  };

  // TODO: Can be generalized into a util function
  const prepareEncryptedData = (toPublicKey) => {
    const userEncryptedData = filterSelectedFields();
    delete userEncryptedData.updatedAt;
    delete userEncryptedData.createdAt;
    const userSecretKey = currentUser.secretKey;
    let asymmetricEncryptedData = {};
    for (let [key, value] of Object.entries(userEncryptedData)) {
      if (
        key !== "brcImage" &&
        key !== "profileImage" &&
        key !== "logo" &&
        key !== "birthCertificate" &&
        key !== "drivingLicense" &&
        key !== "marriageCertificate" &&
        key !== "signature" &&
        key !== "managerSignature" &&
        key !== "passportImage" &&
        key !== "addressImage"
      ) {
        let field = encryption.symmetricDecryption(value.data, userSecretKey);
        // Asymmetric encryption
        asymmetricEncryptedData[key] = encryption.asymmetricEncryption(
          field,
          toPublicKey,
          userSecretKey
        );
      } else {
        const tempArr = [];
        userEncryptedData[key]?.map((item, key) => {
          let field = encryption.symmetricDecryption(item.data, userSecretKey);
          const enc = encryption.asymmetricEncryption(
            field,
            toPublicKey,
            userSecretKey
          );
          tempArr.push(enc);
        });
        asymmetricEncryptedData[key] = tempArr;
      }
    }
    return asymmetricEncryptedData;
  };

  async function reviewConsent(data) {
    const toData = prepareEncryptedData(data.publicKey);
    try {
      const response = await API.reviewConsent({
        body: {
          toData: toData,
        },
        path: {
          cId: data.id,
        },
        query: {
          status: data.status,
        },
        token: localStorage.niftoken,
      });
      if (response.status === 200) {
        setSelectedId((selectedId) => [...selectedId, data]);
        setActiveItem(0);
        if (view === 1) {
          setAcceptedConsents([]);
          retrieveAcceptedConsentsAPI();
        } else if (view === 2) {
          setPendingConsents([]);
          retrievePendingConsentsAPI();
        } else if (view === 3) {
          setAllConsents([]);
          retrieveAllConsents();
        }
        const message =
          data.status === "approve"
            ? "Successfully Approved"
            : "Successfully Removed";
        enqueueSnackbar(message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `Could not review consent at the moment. Please try again later`,
        {
          variant: "error",
        }
      );
    } finally {
      setShowAction(false);
    }
  }

  const handleReviewConsent = (data) => {
    setConsentData(data);
    // Modal
    seOpenSelectionModal(true);
  };

  useEffect(() => {
    if (showAction) {
      reviewConsent(consentData);
    }
  }, [showAction]);

  useEffect(() => {
    setSelectedId([]);
    setActiveItem(0);
    if (view === 1) {
      setAcceptedConsents([]);
      retrieveAcceptedConsentsAPI();
    } else if (view === 2) {
      setPendingConsents([]);
      retrievePendingConsentsAPI();
    } else if (view === 3) {
      setAllConsents([]);
      retrieveAllConsents();
    }
  }, [view]);

  useEffect(() => {
    if (merchants && merchants.alias && view !== 4) {
      if (componentMounted) setView(4);
    }
    return () => {
      // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    };
  }, [clickIdMerchant]);

  return (
    <Container>
      <FieldSelectionModal
        openSelectionModal={openSelectionModal}
        setFieldSelections={setFieldSelections}
        setShowAction={setShowAction}
        userData={activeItem}
      />
      <WrapperContainer>
        {(() => {
          switch (view) {
            case 0:
              return (
                <Banner
                  headingOne="Search"
                  textOne=" Search for merchants or users from the list"
                  imageOne={Searching}
                  headingTwo="Select Duration"
                  textTwo="Specify time duration"
                  imageTwo={Like}
                  headingThree="Approve"
                  textThree="Approve selected merchant"
                  imageThree={Timeline}
                />
              );
            case 4:
              return <ProfileView data={merchants && merchants} />;
            default:
              return (
                <>
                  {view === 3 ? (
                    <SearchResultContainer>
                      <FlexContainer>
                        <Heading color="#2F3A60">
                          Blockchain Transactions
                        </Heading>
                        {loadingAll ? <CircularProgress size={30} /> : null}
                      </FlexContainer>
                      <ScrollWrapper>
                        <ScrollContainer>
                          <TableContainer>
                            <TableWrapper>
                              {loadingAll ? (
                                <>
                                  <LoadingRow />
                                  <LoadingRow />
                                  <LoadingRow />
                                  <LoadingRow />
                                </>
                              ) : allConsents?.length > 0 ? (
                                <Table
                                  theadData={theadData}
                                  tbodyData={allConsents}
                                  transactions
                                />
                              ) : (
                                <Text style={{ marginTop: "1rem" }} primary>
                                  No blockchain transactions found
                                </Text>
                              )}
                            </TableWrapper>
                          </TableContainer>
                        </ScrollContainer>
                      </ScrollWrapper>
                    </SearchResultContainer>
                  ) : (
                    <ViewLayout>
                      <SearchResultContainer>
                        <FlexContainer>
                          <Heading color="#2F3A60">
                            {(() => {
                              switch (view) {
                                case 1:
                                  return "Added Corporates";
                                case 2:
                                  return "Corporate Requests";
                                default:
                                  return "Added Corporates";
                              }
                            })()}
                          </Heading>
                          {loadingAccepted || loadingPending ? (
                            <CircularProgress size={30} />
                          ) : null}
                        </FlexContainer>
                        <ScrollWrapper>
                          <ScrollContainer>
                            {view === 1 ? (
                              acceptedConsents?.length > 0 ? (
                                acceptedConsents.map((item, key) => {
                                  return (
                                    <RowCard
                                      key={key}
                                      id={item?._id}
                                      publicKey={
                                        item?.requestedTo ===
                                        currentUser.publicKey
                                          ? item?.requestedBy
                                          : item?.requestedTo
                                      }
                                      onClick={() => {
                                        setActiveItem(item);
                                      }}
                                      image={
                                        item?.fromData?.profileImageUrl ||
                                        AnonymousImage
                                      }
                                      name={
                                        item?.requestedTo ===
                                        currentUser.publicKey
                                          ? item?.requestedByAlias
                                          : item?.requestedToAlias
                                      }
                                      description={"  "}
                                      type={view}
                                      onPress={(data) => {
                                        handleReviewConsent(data);
                                      }}
                                      isSelected={
                                        item._id === selectedId[key]?.id
                                          ? selectedId[key]?.status ===
                                            "approve"
                                            ? "Approved"
                                            : "Rejected"
                                          : null
                                      }
                                    />
                                  );
                                })
                              ) : (
                                <>
                                  {!loadingAccepted ? (
                                    <Text style={{ marginTop: "1rem" }} primary>
                                      No added corporates found
                                    </Text>
                                  ) : null}
                                </>
                              )
                            ) : view === 2 && pendingConsents?.length > 0 ? (
                              pendingConsents.map((item, key) => {
                                return (
                                  <RowCard
                                    key={key}
                                    id={item?._id}
                                    publicKey={
                                      item?.requestedTo ===
                                      currentUser.publicKey
                                        ? item?.requestedBy
                                        : item?.requestedTo
                                    }
                                    onClick={() => {
                                      setActiveItem(item);
                                    }}
                                    image={
                                      item?.fromData?.profileImage ||
                                      AnonymousImage
                                    }
                                    name={item?.requestedByAlias}
                                    description={"  "}
                                    type={view}
                                    onPress={(data) => {
                                      handleReviewConsent(data);
                                    }}
                                    isSelected={
                                      item._id === selectedId[key]?.id
                                        ? selectedId[key]?.status === "approve"
                                          ? "Approved"
                                          : "Rejected"
                                        : null
                                    }
                                  />
                                );
                              })
                            ) : (
                              <>
                                {!loadingPending ? (
                                  <Text style={{ marginTop: "1rem" }} primary>
                                    No pending requests found
                                  </Text>
                                ) : null}
                              </>
                            )}
                          </ScrollContainer>
                        </ScrollWrapper>
                      </SearchResultContainer>
                      <Line />
                      {activeItem === 0 ? (
                        <Banner
                          headingOne="Search"
                          textOne=" Search for merchants or users from the list"
                          imageOne={Searching}
                          headingTwo="Select Duration"
                          textTwo="Specify time duration"
                          imageTwo={Like}
                          headingThree="Approve"
                          textThree="Approve selected merchant"
                          imageThree={Timeline}
                        />
                      ) : (
                        <ProfileView
                          type={view}
                          data={activeItem}
                          publicKey={
                            activeItem?.requestedTo === currentUser.publicKey
                              ? activeItem?.requestedBy
                              : activeItem?.requestedTo
                          }
                          id={activeItem?._id}
                          onPress={(data) => {
                            handleReviewConsent(data);
                          }}
                          isSelected={
                            activeItem?._id ===
                            selectedId[selectedId?.length - 1]?.id
                              ? selectedId[selectedId?.length - 1]?.status ===
                                "approve"
                                ? "Approved"
                                : "Rejected"
                              : null
                          }
                        />
                      )}
                    </ViewLayout>
                  )}
                </>
              );
          }
        })()}
      </WrapperContainer>
    </Container>
  );
};

export default CorporatesContainer;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

import styled from "styled-components";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import CustomTextField from "../../components/shared/fields/TextField/CustomTextField";
import { DateComponent } from "../../components/shared/Date/DateComponent";
import Select from "react-select";
import DropzoneComponent from "../../components/shared/fields/Dropzone/DropzoneComponent";
import { useSnackbar } from "notistack";
import { ADD_FORM_DATA } from "../../redux/constants/ActionTypes";
import store from "../../redux/reducers";
import { Container } from "./VerifyDetails";
import { Heading, SubHeading } from "../../components/shared/Typography/Typo";
import BlockForm from "../../components/shared/Form/BlockForm";
import { useStateValue } from "../../context/StateProvider";
import { useMediaQuery } from "@mui/material";
import BlockFormView from "../../components/shared/Form/BlockFormView";

// country select options
const countryOptions = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];
// gender select options
const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];
// account type select options
const accountTypeOptions = [
  { value: "savings", label: "Savings Account" },
  { value: "current", label: "Current Account" },
];
// business nature select options
const businessNatureOptions = [
  { value: "private", label: "Private Sector" },
  { value: "public", label: "Public Sector" },
];
// currency code select options
const currencyCodeOptions = [
  { value: "LKR", label: "Sri Lankan Rupee" },
  { value: "INR", label: "Indian Rupee" },
  { value: "USD", label: "US Dollar" },
  { value: "GBP", label: "Sterling Pound" },
];
// marital status select options
const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
];
// residential status select options
const residentialStatusOptions = [
  { value: "local", label: "Local" },
  { value: "foreign", label: "Foreign" },
];

var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;
// old and new NIC validation
var nicValidation = /^(?:19|20)?\d{2}[0-9]{10}|[0-9]{9}[x|X|v|V]$/;
var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PersonalDetails = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  otherNames,
  setOtherNames,
  maidenName,
  setMaidenName,
  nameWithInitials,
  setNameWithInitials,
  dateOfBirth,
  setDateOfBirth,
  nicIssueDate,
  setNicIssueDate,
  placeOfBirth,
  setPlaceOfBirth,
  nationality,
  setNationality,
  occupation,
  setOccupation,
  gender,
  setGender,
  addressL1,
  setAddressL1,
  addressL2,
  setAddressL2,
  city,
  setCity,
  country,
  setCountry,
  postalCode,
  setPostalCode,
  passportIssueCountry,
  setPassportIssueCountry,
  phoneNumber,
  setPhoneNumber,
  faxNumber,
  setFaxNumber,
  phoneNumberError,
  setPhoneNumberError,
  email,
  setEmail,
  emailError,
  setEmailError,
  nic,
  setNic,
  nicError,
  setNicError,
  profileImageBase64,
  setProfileImageBase64,
  birthCertificateBase64,
  setBirthCertificateBase64,
  drivingLicenseBase64,
  setDrivingLicenseBase64,
  marriageCertificateBase64,
  setMarriageCertificateBase64,
  signatureBase64,
  setSignatureBase64,
  managerSignatureBase64,
  setManagerSignatureBase64,
  branchNumber,
  setBranchNumber,
  accountType,
  setAccountType,
  businessNature,
  setBusinessNature,
  purpose,
  setPurpose,
  employerName,
  setEmployerName,
  currencyCode,
  setCurrencyCode,
  employerAddress,
  setEmployerAddress,
  cifNumber,
  setCifNumber,
  incomeSource,
  setIncomeSource,
  expectedAnnualIncome,
  setExpectedAnnualIncome,
  principalAccount,
  setPrincipalAccount,
  panNumber,
  setPanNumber,
  foreignAddress,
  setForeignAddress,
  countryOfBirth,
  setCountryOfBirth,
  ckycNumber,
  setCkycNumber,
  maritalStatus,
  setMaritalStatus,
  bankName,
  setBankName,
  branch,
  setBranch,
  folioNumber,
  setFolioNumber,
  residentialStatus,
  setResidentialStatus,
  setFormStep,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [{ fields }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");

  // formData
  const getFormData = (target) => {
    let productObject = {};
    const formData = new FormData(target);
    for (let [key, value] of formData.entries()) {
      productObject[key] = value;
    }
    // --- images --- //
    productObject["profileImage"] = profileImageBase64;
    productObject["birthCertificate"] = birthCertificateBase64;
    productObject["drivingLicense"] = drivingLicenseBase64;
    productObject["marriageCertificate"] = marriageCertificateBase64;
    productObject["signature"] = signatureBase64;
    productObject["managerSignature"] = managerSignatureBase64;
    // --- Country --- //
    productObject["country"] = country?.label;
    return productObject;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (profileImageBase64?.length === 0) {
      enqueueSnackbar(`Please input an image for profile picture`, {
        variant: "error",
      });
      return false;
    } else if (birthCertificateBase64?.length === 0) {
      enqueueSnackbar(`Please input images of your Birth Certificate`, {
        variant: "error",
      });
      return false;
    } else if (signatureBase64?.length === 0) {
      enqueueSnackbar(`Please input an image of your signature`, {
        variant: "error",
      });
      return false;
    } else if (!nicError || !phoneNumberError || !emailError) {
      setFormStep(2);
    }
    try {
      const productObject = getFormData(e.currentTarget);
      store.dispatch({
        type: ADD_FORM_DATA,
        payload: productObject,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <FormContainer id="main-form" onSubmit={handleSubmit}>
        <Heading lighter primary style={{ marginBottom: "1rem" }}>
          Personal Information
        </Heading>
        <GridContainer>
          <CustomTextField
            label="First Name"
            placeholder="First Name"
            id="firstName"
            required
            name="firstName"
            type="text"
            value={firstName}
            onChange={setFirstName}
            color="#FF613F"
            maxLength="20"
          />
          <CustomTextField
            label="Last Name"
            placeholder="Last Name"
            id="lastName"
            required
            name="lastName"
            type="text"
            value={lastName}
            onChange={setLastName}
            color="#FF613F"
            maxLength="20"
          />
          <CustomTextField
            label="Maiden Name - Optional"
            placeholder="Maiden Name"
            id="maidenName"
            name="maidenName"
            type="text"
            value={maidenName}
            onChange={setMaidenName}
            color="#FF613F"
            maxLength="30"
          />
          <CustomTextField
            label="Other Names - Optional"
            placeholder="Other Names"
            id="otherNames"
            name="otherNames"
            type="text"
            value={otherNames}
            onChange={setOtherNames}
            color="#FF613F"
            maxLength="30"
          />
          <CustomTextField
            label="Name with Initials"
            placeholder="Name with Initials"
            id="nameWithInitials"
            required
            name="nameWithInitials"
            type="text"
            value={nameWithInitials}
            onChange={setNameWithInitials}
            color="#FF613F"
            maxLength="40"
          />
          <DateComponent
            label="Date of Birth"
            value={dateOfBirth}
            onChange={(date) => setDateOfBirth(date)}
          />
          <CustomTextField
            label="Place of Birth"
            placeholder="Place of Birth"
            id="placeOfBirth"
            name="placeOfBirth"
            type="text"
            value={placeOfBirth}
            required
            onChange={setPlaceOfBirth}
            color="#FF613F"
            maxLength="20"
          />
          <ColumnContainer>
            <Label>Country of Birth</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={countryOptions[0]}
              value={countryOfBirth}
              onChange={setCountryOfBirth}
              options={countryOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="Nationality"
            placeholder="Nationality"
            id="nationality"
            name="nationality"
            type="text"
            value={nationality}
            required
            onChange={setNationality}
            color="#FF613F"
            maxLength="20"
          />
          <ColumnContainer>
            <Label>Gender</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={genderOptions[0]}
              value={gender}
              onChange={setGender}
              options={genderOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="Address Line 1"
            placeholder="Address Line 1"
            id="addressL1"
            name="addressL1"
            type="text"
            value={addressL1}
            required
            onChange={setAddressL1}
            color="#FF613F"
            maxLength="50"
          />
          <CustomTextField
            label="Address Line 2 - Optional"
            placeholder="Address Line 2"
            id="addressL2"
            name="addressL2"
            type="text"
            value={addressL2}
            onChange={setAddressL2}
            color="#FF613F"
            maxLength="50"
          />
          <CustomTextField
            label="City"
            placeholder="City"
            id="city"
            name="city"
            type="text"
            value={city}
            required
            onChange={setCity}
            color="#FF613F"
            maxLength="20"
          />
          <ColumnContainer>
            <Label>Country</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={countryOptions[0]}
              value={country}
              onChange={setCountry}
              options={countryOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="Postal Code"
            placeholder="Postal Code"
            id="postalCode"
            name="postalCode"
            type="number"
            value={postalCode}
            required
            onChange={setPostalCode}
            color="#FF613F"
            maxLength="5"
          />
          <CustomTextField
            label="Foreign Address - Optional"
            placeholder="Foreign Address"
            id="foreignAddress"
            name="foreignAddress"
            type="text"
            value={foreignAddress}
            onChange={setForeignAddress}
            color="#FF613F"
            maxLength="100"
          />
          <CustomTextField
            label="Mobile Number"
            placeholder="0/ 94/ +94/ 0094"
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            value={phoneNumber}
            required
            maxLength="13"
            onChange={(e) => {
              if (!phoneNoValidation.test(e)) {
                setPhoneNumberError(true);
              } else {
                setPhoneNumberError(false);
              }
              setPhoneNumber(e);
            }}
            color="#FF613F"
            helper={phoneNumberError && "Invalid mobile number"}
          />
          <CustomTextField
            label="Email"
            placeholder="Email"
            id="email"
            name="email"
            type="email"
            value={email}
            required
            maxLength="50"
            onChange={(e) => {
              if (!emailValidation.test(e)) {
                setEmailError(true);
              } else {
                setEmailError(false);
              }
              setEmail(e);
            }}
            helper={emailError && "Invalid Email"}
            color="#FF613F"
          />
          <ColumnContainer>
            <Label>Marital Status</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={maritalStatusOptions[0]}
              value={maritalStatus}
              onChange={setMaritalStatus}
              options={maritalStatusOptions}
            />
          </ColumnContainer>
          <ColumnContainer>
            <Label>Residential Status</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={residentialStatusOptions[0]}
              value={residentialStatus}
              onChange={setResidentialStatus}
              options={residentialStatusOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="NIC / Passport / Driving License No."
            placeholder="NIC / Passport / Driving License No."
            id="nic"
            name="nic"
            type="text"
            value={nic}
            required
            maxLength="12"
            // onChange={(e) => {
            //   if (!nicValidation.test(e)) {
            //     setNicError(true);
            //   } else {
            //     setNicError(false);
            //   }
            //   setNic(e);
            // }}
            onChange={setNic}
            color="#FF613F"
            // helper={nicError && "Invalid NIC number"}
          />
          <DateComponent
            label="NIC Issue Date"
            value={nicIssueDate}
            onChange={(date) => setNicIssueDate(date)}
          />
          <ColumnContainer>
            <Label>Passport Issued Country</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={countryOptions[0]}
              value={passportIssueCountry}
              onChange={setPassportIssueCountry}
              options={countryOptions}
            />
          </ColumnContainer>
          <Column span={!matches ? true : false}>
            {fields && fields.length > 0 && (
              <SubHeading style={{ marginTop: "1rem" }} primary>
                Child Details
              </SubHeading>
            )}
            {fields &&
              fields.length > 0 &&
              fields.map((item, key) => {
                return <BlockFormView data={item} key={key} />;
              })}
            <Column>
              <BlockForm />
            </Column>
          </Column>
          <ColumnContainer>
            <Label>Profile Picture</Label>
            <DroppableContainer>
              <InnerContainer>
                <DropzoneComponent
                  files={profileImageBase64}
                  id="dropzone"
                  amount="1"
                  multiple={false}
                  maxSize="1000000"
                  setFiles={setProfileImageBase64}
                  type="image/*, image/jpeg, image/png"
                  description="Select or Drop an Image"
                />
              </InnerContainer>
            </DroppableContainer>
          </ColumnContainer>
          <ColumnContainer>
            <Label>Birth Certificate</Label>
            <DroppableContainer>
              <InnerContainer>
                <DropzoneComponent
                  files={birthCertificateBase64}
                  id="dropzone"
                  amount="2"
                  multiple={true}
                  maxSize="2000000"
                  setFiles={setBirthCertificateBase64}
                  type="image/*, image/jpeg, image/png"
                  description="Select or Drop Images"
                />
              </InnerContainer>
            </DroppableContainer>
          </ColumnContainer>
          <ColumnContainer>
            <Label>Driving License - Optional</Label>
            <DroppableContainer>
              <InnerContainer>
                <DropzoneComponent
                  files={drivingLicenseBase64}
                  id="dropzone"
                  amount="2"
                  multiple={true}
                  maxSize="2000000"
                  setFiles={setDrivingLicenseBase64}
                  type="image/*, image/jpeg, image/png"
                  description="Select or Drop Images"
                />
              </InnerContainer>
            </DroppableContainer>
          </ColumnContainer>
          {maritalStatus.value === "married" ? (
            <ColumnContainer>
              <Label>Marriage Certificate - Optional</Label>
              <DroppableContainer>
                <InnerContainer>
                  <DropzoneComponent
                    files={marriageCertificateBase64}
                    id="dropzone"
                    amount="2"
                    multiple={true}
                    maxSize="2000000"
                    setFiles={setMarriageCertificateBase64}
                    type="image/*, image/jpeg, image/png"
                    description="Select or Drop Images"
                    required={maritalStatus.value === "married"}
                  />
                </InnerContainer>
              </DroppableContainer>
            </ColumnContainer>
          ) : null}

          <ColumnContainer>
            <Label>Signature</Label>
            <DroppableContainer>
              <InnerContainer>
                <DropzoneComponent
                  files={signatureBase64}
                  id="dropzone"
                  amount="1"
                  multiple={false}
                  maxSize="1000000"
                  setFiles={setSignatureBase64}
                  type="image/*, image/jpeg, image/png"
                  description="Select or Drop an Image"
                />
              </InnerContainer>
            </DroppableContainer>
          </ColumnContainer>
        </GridContainer>
        <Heading
          lighter
          primary
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
        >
          Work Information - Optional
        </Heading>
        <GridContainer>
          <CustomTextField
            label="Occupation"
            placeholder="Occupation"
            id="occupation"
            name="occupation"
            type="text"
            value={occupation}
            // required
            onChange={setOccupation}
            color="#FF613F"
            maxLength="25"
          />
          <CustomTextField
            label="Fax Number"
            placeholder="0/ 94/ +94/ 0094"
            id="faxNumber"
            name="faxNumber"
            type="text"
            value={faxNumber}
            // required
            maxLength="13"
            onChange={(e) => {
              if (!phoneNoValidation.test(e)) {
                setPhoneNumberError(true);
              } else {
                setPhoneNumberError(false);
              }
              setFaxNumber(e);
            }}
            color="#FF613F"
            helper={phoneNumberError && "Invalid Fax number"}
          />
          <CustomTextField
            label="Name of Employer"
            placeholder="Name of Employer"
            id="employerName"
            name="employerName"
            type="text"
            value={employerName}
            // required
            onChange={setEmployerName}
            color="#FF613F"
            maxLength="30"
          />
          <CustomTextField
            label="Employer's Address"
            placeholder="Employer's Address"
            id="employerAddress"
            name="employerAddress"
            type="text"
            value={employerAddress}
            // required
            onChange={setEmployerAddress}
            color="#FF613F"
            maxLength="100"
          />
        </GridContainer>
        <Heading
          lighter
          primary
          style={{ marginTop: "2rem", marginBottom: "1rem" }}
        >
          Bank Information - Optional
        </Heading>
        <GridContainer>
          <CustomTextField
            label="Bank Name"
            placeholder="Bank Name"
            id="bankName"
            name="bankName"
            type="text"
            value={bankName}
            // required
            onChange={setBankName}
            color="#FF613F"
            maxLength="30"
          />
          <CustomTextField
            label="Branch"
            placeholder="Branch"
            id="branch"
            name="branch"
            type="text"
            value={branch}
            // required
            onChange={setBranch}
            color="#FF613F"
            maxLength="20"
          />
          <CustomTextField
            label="Branch Number"
            placeholder="Branch Number"
            id="branchNumber"
            name="branchNumber"
            type="number"
            value={branchNumber}
            // required
            onChange={setBranchNumber}
            color="#FF613F"
            maxLength="10"
          />
          <ColumnContainer>
            <Label>Account Type</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={accountTypeOptions[0]}
              value={accountType}
              onChange={setAccountType}
              options={accountTypeOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="Purpose of Opening the Account"
            placeholder="Purpose of Opening the Account"
            id="purpose"
            name="purpose"
            type="text"
            value={purpose}
            // required
            onChange={setPurpose}
            color="#FF613F"
            maxLength="50"
          />
          <CustomTextField
            label="CIF Number"
            placeholder="CIF Number"
            id="cifNumber"
            name="cifNumber"
            type="number"
            value={cifNumber}
            // required
            onChange={setCifNumber}
            color="#FF613F"
            maxLength="10"
          />
          <CustomTextField
            label="Sources of Income"
            placeholder="Sources of Income"
            id="incomeSource"
            name="incomeSource"
            type="text"
            value={incomeSource}
            // required
            onChange={setIncomeSource}
            color="#FF613F"
            maxLength="100"
          />
          <CustomTextField
            label="Expected Annual Income"
            placeholder="Expected Annual Income"
            id="expectedAnnualIncome"
            name="expectedAnnualIncome"
            type="number"
            value={expectedAnnualIncome}
            // required
            onChange={setExpectedAnnualIncome}
            color="#FF613F"
            maxLength="10"
          />
          <ColumnContainer>
            <Label>Currency Code</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={currencyCodeOptions[0]}
              value={currencyCode}
              onChange={setCurrencyCode}
              options={currencyCodeOptions}
            />
          </ColumnContainer>
          <ColumnContainer>
            <Label>Nature of Business</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#eaeaf3",
                  boxShadow: "none",
                },
              })}
              defaultValue={businessNatureOptions[0]}
              value={businessNature}
              onChange={setBusinessNature}
              options={businessNatureOptions}
            />
          </ColumnContainer>
          <CustomTextField
            label="Principal Account"
            placeholder="Principal Account"
            id="principalAccount"
            name="principalAccount"
            type="text"
            value={principalAccount}
            // required
            onChange={setPrincipalAccount}
            color="#FF613F"
            maxLength="20"
          />
          <CustomTextField
            label="PAN Number"
            placeholder="PAN Number"
            id="panNumber"
            name="panNumber"
            type="number"
            value={panNumber}
            // required
            onChange={setPanNumber}
            color="#FF613F"
            maxLength="10"
          />
          <CustomTextField
            label="CKYC Number"
            placeholder="CKYC Number"
            id="ckycNumber"
            name="ckycNumber"
            type="number"
            value={ckycNumber}
            // required
            onChange={setCkycNumber}
            color="#FF613F"
            maxLength="15"
          />
          <CustomTextField
            label="Folio Number"
            placeholder="Folio Number"
            id="folioNumber"
            name="folioNumber"
            type="number"
            value={folioNumber}
            // required
            onChange={setFolioNumber}
            color="#FF613F"
            maxLength="15"
          />
          <ColumnContainer>
            <Label>Manager's Signature</Label>
            <DroppableContainer>
              <InnerContainer>
                <DropzoneComponent
                  files={managerSignatureBase64}
                  id="dropzone"
                  amount="1"
                  multiple={false}
                  maxSize="1000000"
                  setFiles={setManagerSignatureBase64}
                  type="image/*, image/jpeg, image/png"
                  description="Select or Drop an Image"
                />
              </InnerContainer>
            </DroppableContainer>
          </ColumnContainer>
        </GridContainer>
        <ButtonWrapper>
          <CustomButton form="main-form" type="submit" login text="Continue" />
        </ButtonWrapper>
      </FormContainer>
    </Container>
  );
};

export default PersonalDetails;

const GridContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  grid-column: 1/3;
  justify-content: flex-end;
`;

const Label = styled.label`
  color: #2f3a60;
  font-weight: 700;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const FormContainer = styled.form`
  display: flex;
  margin: 1rem;
  flex-direction: column;
  padding: 0.5rem 0rem;
  @media (max-width: 768px) {
    margin: 0.5rem;
  }
`;
